<template>
  <tag
    color="periwinkle-light"
    fa-icon="chair"
    with-asterisk
    :public-directory="publicDirectory"
  >
    <slot>{{label}}</slot>
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },

  props: {
    openSpotsCount: Number,
    publicDirectory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    label() {
      if (this.openSpotsCount) {
        return `${this.openSpotsCount} spot${this.openSpotsCount > 1 ? 's' : ''} available!`;
      }
      return 'Open spots!';
    },
  },
};
</script>
