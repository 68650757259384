<template>
  <li
    class="feature-item font-body-small"
    :class="{ 'feature-item--empty': !text && !$slots.default,
              'feature-item--public': publicDirectory }"
  >
    <span :class="iconClass"></span>
    <span class="font-body-small feature-item__label" >
      <slot>{{text}}</slot>
    </span>
  </li>
</template>

<script>
export default {
  props: {
    faIcon: {
      type: String,
      required: true,
    },

    text: {
      type: String,
    },

    publicDirectory: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconClass() {
      return `fal fa-${this.faIcon} feature-item__icon`;
    },

    isEmpty() {
      return !this.text.trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-item {
  display: grid;
  grid-template-columns: calc(var(--grid-unit) * 2) 1fr;
  gap: calc(var(--grid-unit) / 2);
  padding: 0;
  margin: 0;
  color: var(--gray-80);

  & + .feature-item {
    margin-top: calc(var(--grid-unit) / 2);

    @media (min-width: 400px) {
      margin-top: var(--grid-unit);
    }
  }

  & > * {
    line-height: calc(var(--grid-unit) * 2);
  }

  &--empty {
    color: var(--gray-30);
  }

  &__icon {
    text-align: center;
  }

  &__label {
    &::first-letter {
      text-transform: capitalize;
    }

    &:empty {
      &::after {
        content: '--';
      }
    }
  }

  &--public {
    .feature-item__icon {
      padding-top: 1px;
    }

    .feature-item__label {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.1;

      /* truncate teaching philosophies longer than 2 lines... */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & + .feature-item {
      margin-top: var(--grid-unit);
    }
  }
}
</style>
