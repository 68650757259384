<template>
  <tag
    class="kinside-partner-badge"
    color="viridian-light"
    text="Kinside Partner"
    fa-icon="star fas"
    :hide-on-mobile="hideOnMobile"
  />
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    hideOnMobile: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.kinside-partner-badge .fal {
  color: var(--viridian-30);
}
</style>
