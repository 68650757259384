<template>
  <tag>
    {{label}}
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    openSpotsCount: Number,
  },
  computed: {
    label() {
      return this.offersCount === 1 ? 'Offer available' : 'Offers available';
    },
  },
};
</script>
