<template>
  <tag color="gray-dark" text="Temporarily Closed" fa-icon="slash" :hide-on-mobile="hideOnMobile"/>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    hideOnMobile: {
      type: Boolean,
    },
  },
};
</script>
