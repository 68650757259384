<template>
  <tag color="periwinkle" fa-icon="stars fas" class="our-pick-badge">
    Our pick
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
};
</script>

<style lang="scss" scoped>
.our-pick-badge {
  &::v-deep .fas:not(.fa-asterisk) {
    color: var(--periwinkle-30);
  }
}
</style>
