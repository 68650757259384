<template>
  <tag fa-icon="coin" :hide-on-mobile="hideOnMobile">
    <span>Paying Partner</span>
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    hideOnMobile: {
      type: Boolean,
    },
  },
};
</script>
