<template>
  <div :class="(!this.publicDirectory && this.conciergeMode) ? 'concierge-mode' : ''">
    <concierge-only-access v-if="!this.publicDirectory && this.conciergeItems">
      <ul class="feature-list feature-list--concierge">
        <feature-item
          v-for="item in conciergeItems"
          :key="item.icon"
          :fa-icon="item.icon"
          :text="item.text"
        />
      </ul>
    </concierge-only-access>

    <ul class="feature-list">
      <feature-item
        v-for="item in items"
        :key="item.icon"
        :fa-icon="item.icon"
        :text="item.text"
        :public-directory="publicDirectory"
      />
    </ul>
  </div>
</template>

<script>
import FeatureItem from './feature-item.vue';

export default {
  components: {
    FeatureItem,
  },
  props: {
    facility: {
      type: Object,
    },
    publicDirectory: {
      type: Boolean,
      default: false,
    },
    showDistance: {
      type: Boolean,
      default: true,
    },
    showCityStateZip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    conciergeMode() {
      return this.$userAccess({ roleIs: ['impersonated', 'admin', 'concierge'] });
    },

    location() {
      if (!this.publicDirectory && this.$store.getters.isPreviewMode) {
        return 'Distance from parent';
      }
      const locationStrings = [];

      if (this.showDistance && (this.facility.distance || this.facility.distance === 0)) {
        locationStrings.push(`${this.facility.distance.toFixed(1)} miles`);
      }

      if (this.showCityStateZip) {
        locationStrings.push(this.cityStateZip);
      }

      return locationStrings.filter((item) => item).join(', ');
    },

    cityStateZip() {
      const cityState = [this.facility.city, this.facility.state].filter((item) => item).join(', ');
      return [cityState, this.facility.zip].filter((item) => item).join(' ');
    },

    conciergeItems() {
      if (this.conciergeMode) {
        const openSpots = this.facility.relatedOpenSpotsCount;
        const openSpotsUpdated = this.facility.relatedSpotsUpdatedAt;
        return [
          {
            icon: 'chair',
            text: openSpots ? `${openSpots} spot${openSpots > 1 ? 's' : ''} (${this.$moment(openSpotsUpdated).fromNow()})` : undefined,
          },
          { icon: 'coin', text: this.facility.providerPlanName },
        ];
      }
      return null;
    },

    items() {
      const classAndPhilosophyItem = {
        icon: 'shapes',
        text: this.facility.classAndPhilosophies,
      };

      const priceItem = {
        icon: 'dollar-sign',
        text: this.facility.displayTuitionString,
      };
      const agesItem = { icon: 'child-reaching', text: this.facility.displayAgeGroupString };

      const locationItem = {
        icon: 'map-pin',
        text: this.location ? this.location : '--',
      };

      const scheduleItem = {
        icon: 'clock',
        text: this.facility.displayScheduleString,
      };

      return [
        locationItem,
        priceItem,
        agesItem,
        scheduleItem,
        classAndPhilosophyItem,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.concierge-mode {
  .concierge-only-access--default {
    margin-bottom: calc(var(--grid-unit) * 2);
  }
}
</style>
