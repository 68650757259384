<template>
  <tag color="coral" fa-icon="hand-holding-medical" :hide-on-mobile="hideOnMobile">
    Emergency Care
  </tag>
</template>

<script>
import Tag from '@components/tag/tag.vue';

export default {
  components: {
    Tag,
  },
  props: {
    hideOnMobile: {
      type: Boolean,
    },
  },
};
</script>
